import { Grid, Typography, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import FadeWrapper from "../../components/FadeWrapper";
import FooterPara from "../../components/FooterPara";
import Logo2 from "../../components/Logo2";
import { baseurl } from "../../constants";
import jmbg03 from "../../img/jmbg03.jpg";
import jpic4 from "../../img/jpic4.jpg";
import jsitebg1 from "../../img/jsitebg1.webp";
import kwlogo from "../../img/kwlogo.png";
import orea from "../../img/orea.svg";
import realtor from "../../img/realtor.svg";
import rhab from "../../img/rhab.png";
import ContactMain from "./ContactMain";
import ListBox from "./ListBox";
import Testimonials from "./Testimonials";
import "./temp.css";

const MainSection = styled("div")`
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: -100;
`;

const ViewButton = styled("div")({
  display: "flex",
  justifyContent: "center",
  border: "1px solid white",
  color: "white",
  margin: "40px",
  padding: "8px 20px",
  width: "200px",
  backgroundImage: "linear-gradient(#ffffff, #ffffff)",
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "0% 100%",
  transition: "background-size 0.5s, color 0.5s",

  "&:hover": {
    color: "#877466",
    backgroundSize: "100% 100%",
    cursor: "pointer",
  },
});
const ViewButtonBlue = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  border: "1px solid #877466",
  color: "white",
  padding: "8px 20px",
  marginTop: "40px",
  width: "200px",
  borderRadius: "0px",
  backgroundColor: "#877466",
  backgroundImage: "linear-gradient(#ffffff, #ffffff)",
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "0% 100%",
  transition: "background-size 0.5s, color 0.5s",
  zIndex: "9999999",

  "&:hover": {
    color: "#877466",
    backgroundSize: "100% 100%",
    cursor: "pointer",
  },
});

function Main({ trig2, setTrig2 }) {
  const theme = useTheme();
  const smHeight = useMediaQuery("(max-height:650px)", { noSsr: true });
  const smWidth = useMediaQuery(theme.breakpoints.down("sm"));

  const parallax = useRef();
  const move = useRef();
  const [allListings, setAllListings] = useState([]);

  useEffect(() => {
    axios.get(`${baseurl}/listings/listingsonly`).then((response) => {
      setAllListings(response.data.bundle);
    });
  }, []);

  const handleScroll = () => {
    if (parallax.current) {
      let pos = parallax.current.current;
      let moved = document.querySelector(".bob");
      moved.style.left = `${-pos / 75 + 20}%`;
      setTrig2(pos);
    }
  };

  useEffect(() => {
    const container = document.querySelector(".parallax-main");
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  });

  const cont = useRef();

  return (
    <>
      <Helmet>
        <title>JM Real Estate Home</title>
        <meta
          name="description"
          content="Buying or Selling real estate in the Hamilton/Haldimand area? Jordan Martindale is a trusted real estate agent serving the Hamilton and Haldimand area"
        />
      </Helmet>
      <MainSection>
        <div
          style={{
            backgroundColor: "rgb(0,0,0)",
            width: "100%",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <picture>
            <source type="image/webp" srcset={jmbg03} />
            <source type="image/jpeg" srcset={jmbg03} />
            <img
              style={{
                padding: "0px",
                margin: "0px",
                opacity: "0.5",
                height: "100vh",
                width: "100%",
                objectFit: "cover",
              }}
              src={jmbg03}
              alt="Jordan Martindale Background"
            />
          </picture>
        </div>
      </MainSection>
      {/* <FadeWrapper duration={100}> */}
      <Box sx={{ display: smHeight || smWidth ? "none" : "block" }}>
        <Parallax pages={4.2} ref={parallax} className="parallax-main">
          <ParallaxLayer
            sticky={{ start: 0, end: 0.25 }}
            offset={0}
            speed={0}
            style={{
              zIndex: 1,
              display: "flex",
              postion: "relative",
              backgroundColor: "transparent",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              justifyContent="flex-end"
              alignContent="center"
              sx={{
                height: "100vh",
                width: "100%",
                marginRight: { xs: "0%", sm: "5%", lg: "7%", xl: "10%" },
              }}
            >
              <Grid
                item
                container
                direction="column"
                sx={{
                  height: "max-content",
                  width: "max-content",
                  backdropFilter: "blur(2px)",
                  dislay: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {/* <img
                  style={{ width: "60vw", maxWidth: "700px" }}
                  alt="JM"
                  src={jmlogo78}
                /> */}
                <Logo2
                  width={{
                    sm: "400px",
                    lg: "600px",
                    xl: "700px",
                  }}
                />

                {/* <Typography
                  sx={{ marginTop: "40px", color: "white.main" }}
                  variant="h4"
                >
                  Hamilton/Haldimand Realtor
                </Typography> */}
                {/* <img
                    style={{ width: "80%", maxWidth: "300px" }}
                    alt="JM"
                    src={mcleod3}
                  /> */}

                <FadeWrapper duration={2000} delay={4200}>
                  <Grid item container justifyContent="space-around">
                    <Grid item>
                      <Link
                        to={`/listings`}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <ViewButton>View Listings</ViewButton>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        to={`/newsletters`}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <ViewButton>Newsletters</ViewButton>
                      </Link>
                    </Grid>
                  </Grid>
                </FadeWrapper>
              </Grid>
            </Grid>
          </ParallaxLayer>
          <ParallaxLayer
            // sticky={{ start: 1, end: 1.5 }}
            offset={1}
            speed={0.1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: "15%",
              zIndex: "9999",
            }}
          >
            <Box sx={{ width: { xs: "35%", lg: "30%" }, height: "auto" }}>
              <Typography variant="h3" sx={{}}>
                Meet Jordan
              </Typography>
              <Box
                sx={{
                  borderBottom: "1px solid #7E7E7E",
                  marginBottom: "40px",
                  marginTop: "10px",
                  width: { xs: "200px", md: "200px" },
                }}
              ></Box>
              <Typography variant="body">
                Jordan Martindale is an experienced real estate agent who
                specializes in selling residential homes and condos in and
                around Hamilton, Ontario. She is extremely dedicated to selling
                her clients properties at the best rate possible, providing
                honest opinions and tips along the way.
              </Typography>
              <Link
                to={`/about`}
                style={{
                  textDecoration: "none",
                  display: "contents",
                }}
              >
                <ViewButtonBlue>About Jordan</ViewButtonBlue>
              </Link>
            </Box>
          </ParallaxLayer>
          <ParallaxLayer
            offset={1}
            speed={0.4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <img
              alt="JM"
              style={{
                width: "32%",
                marginRight: "10%",
                boxShadow: "0 10px 20px rgba(0,0,0,0.19)",
              }}
              src={jpic4}
            />
          </ParallaxLayer>
          {/* <ParallaxLayer
          offset={1}
          speed={0}
          style={{
            zIndex: "1",
            display: "flex",
            backgroundColor: "",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></ParallaxLayer> */}

          <ParallaxLayer
            offset={2}
            speed={0}
            style={{ zIndex: "999", backgroundColor: "transparent" }}
          >
            <Box
              sx={{
                position: "relative",
                zIndex: "999",
                width: "100%",
                height: "20%",
                bgcolor: "#222222",
                margin: "0 auto",
              }}
            >
              <div
                className="bob"
                ref={move}
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  position: "absolute",
                  left: "0%",
                }}
              >
                <img
                  alt="remax"
                  src={kwlogo}
                  style={{
                    width: "12%",
                    maxWidth: "220px",
                  }}
                />
                <img
                  alt="orea"
                  src={orea}
                  style={{
                    width: "11%",
                    maxWidth: "200px",
                  }}
                />
                <img
                  alt="RHAB"
                  src={rhab}
                  style={{
                    width: "11%",
                    maxWidth: "150px",
                  }}
                />
                <img
                  alt="realtor"
                  src={realtor}
                  style={{
                    width: "8%",
                    maxWidth: "120px",
                  }}
                />
              </div>
            </Box>
          </ParallaxLayer>
          <ParallaxLayer
            factor={1.2}
            offset={1}
            speed={0}
            style={{
              backgroundColor: "#ffffff",
              zIndex: "-99",
            }}
          />
          <ParallaxLayer
            offset={2.2}
            speed={0}
            factor={0.8}
            style={{
              backgroundColor: `white`,
              zIndex: "999",
            }}
          />
          <ParallaxLayer
            offset={2.2}
            speed={0.1}
            factor={0.8}
            style={{
              zIndex: "99999",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ margin: "0px 0px", width: "100%", textAlign: "center" }}
              variant="h3"
              id="testimonials"
            >
              Testimonials
            </Typography>
            <Box sx={{ zIndex: "9999999999" }}>
              <Testimonials />
            </Box>
          </ParallaxLayer>

          <ParallaxLayer
            factor={1.2}
            offset={3.0}
            speed={0}
            style={{
              zIndex: "1000",
              backgroundImage: `url(${jsitebg1})`,
            }}
          >
            <Grid
              container
              alignItems="center"
              style={{
                backgroundColor: "rgb(0,0,0,0.1)",
                width: "100%",
                height: "100vh",
              }}
            >
              <Grid
                item
                container
                sm={12}
                md={6}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  sx={{
                    maxWidth: "900px",
                    width: "90%",
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    [theme.breakpoints.only("sm")]: {
                      display: "none",
                    },
                  }}
                >
                  <Typography color="text.white" variant="h3">
                    View Listings
                  </Typography>
                  <Link
                    to={`/listings`}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <ViewButton>View Listings</ViewButton>
                  </Link>
                  {allListings[0] && <ListBox info={allListings[0]} />}
                </Box>
              </Grid>
              <Grid
                ref={cont}
                id="contact"
                item
                container
                sm={12}
                md={6}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <ContactMain />
              </Grid>
            </Grid>
            <FooterPara />
          </ParallaxLayer>
        </Parallax>
      </Box>

      {/* </FadeWrapper> */}

      <Box
        sx={{
          display: smHeight || smWidth ? "block" : "none",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100vh",
            width: "100%",
          }}
        >
          <Grid
            item
            container
            direction="column"
            sx={{
              backdropFilter: "blur(1px)",
              dislay: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: "50px",
            }}
          >
            {/* <img
              style={{ width: "85%", maxWidth: "400px" }}
              alt="JM"
              src={jmlogo78}
            /> */}
            <Logo2 maxWidth="400px" width="85%" />
            {/* <Typography
              sx={{ marginTop: "40px", color: "white.main" }}
              variant="h4"
            >
              Hamilton/Haldimand Realtor
            </Typography> */}

            <FadeWrapper duration={2000} delay={4200}>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                sx={{ marginTop: "20px" }}
              >
                <Grid item>
                  <Link
                    to={`/listings`}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <ViewButton style={{ margin: "10px" }}>
                      View Listings
                    </ViewButton>
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    to={`/newsletters`}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <ViewButton style={{ margin: "10px" }}>
                      Newsletters
                    </ViewButton>
                  </Link>
                </Grid>
              </Grid>
            </FadeWrapper>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          sx={{
            minHeight: "70vh",
            backgroundImage: `url(${jpic4})`,
            backgroundSize: "cover",
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            sx={{
              padding: "20px",
              backgroundColor: "purpleT.dark",
            }}
          >
            <Typography
              color="text.white"
              variant="h3"
              sx={{ marginBottom: "30px" }}
            >
              Meet Jordan
            </Typography>
            <Typography color="text.white" variant="body">
              Jordan Martindale is an experienced real estate agent who
              specializes in selling residential homes and condos in and around
              Hamilton, Ontario. She is extremely dedicated to selling her
              clients properties at the best rate possible, providing honest
              opinions and tips along the way.
            </Typography>
            <Link
              to={`/about`}
              style={{
                textDecoration: "none",
                display: "contents",
              }}
            >
              <ViewButton>About Jordan</ViewButton>
            </Link>
          </Grid>
        </Grid>
        <Typography
          id="testimonials-mobile"
          sx={{
            margin: "30px 0px 0px 0px",
            width: "100%",
            textAlign: "center",
          }}
          variant="h3"
        >
          Testimonials
        </Typography>
        <Testimonials />

        <Grid
          container
          alignItems="center"
          style={{
            backgroundColor: "rgb(0,0,0,0.1)",
            width: "100%",
            paddingBottom: "40px",
            backgroundImage: `url(${jsitebg1})`,
            backgroundSize: "cover",
            backgroundRepeat: "repeat",
          }}
        >
          <Grid
            item
            container
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ padding: "30px 0px" }}
          >
            <Box
              sx={{
                maxWidth: "900px",
                width: "95%",
                display: "flex",
                marginBottom: "20px",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography color="text.white" variant="h3">
                View Listings
              </Typography>
              <Link
                to={`/listings`}
                style={{
                  textDecoration: "none",
                }}
              >
                <ViewButton>View Listings</ViewButton>
              </Link>
              {allListings[0] && <ListBox info={allListings[0]} />}
            </Box>
          </Grid>
          <Grid
            ref={cont}
            id="contact-mobile"
            item
            container
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <ContactMain />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Main;
