import { Box, Typography } from "@mui/material";
import React from "react";

function NoListings() {
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundColor: "rgb(34,34,34)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" color="text.white">
        No Listing was found
      </Typography>
    </Box>
  );
}

export default NoListings;
