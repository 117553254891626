import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Grid, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { animated, config, useSpring } from "react-spring";
import abouttop from "../../img/abouttop.jpg";
import htown2 from "../../img/htown2.jpg";
import jpic33 from "../../img/jpic33.jpg";

const ImageWelcome = styled("div")(({ theme }) => ({
  backgroundImage: `url(${abouttop})`,
  height: "600px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    height: "500px",
  },
  [theme.breakpoints.down("md")]: {
    height: "400px",
    paddingTop: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "350px",
    paddingTop: "30px",
  },

  "&:after": {
    content: '" "',
    zIndex: 2,
    display: "block",
    position: "absolute",
    height: "600px",
    top: "0",
    left: "0",
    right: "0",
    background: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.down("lg")]: {
      height: "500px",
    },
    [theme.breakpoints.down("md")]: {
      height: "400px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "350px",
    },
  },
}));

const AnimatedTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  fontFamily: '"Montserrat", sans-serif',
  fontWeight: "400",
}));
const ImgContainer = styled("img")(({ theme }) => ({
  left: "10%",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  zIndex: 1,
  height: "600px",
}));
const ImgBox = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1,
  width: "100%",
  overflow: "hidden",
  height: "600px",

  "&:after": {
    content: '" "',
    zIndex: 2,
    display: "block",
    position: "absolute",
    height: "600px",
    top: "0",
    left: "0",
    right: "0",
    background: "rgba(0, 0, 0, 0.3)",
  },
}));
const ImgSlide = styled("img")(({ theme }) => ({
  width: "100%",
  boxShadow: "1px 1px 10px rgb(0,0,0,0.3)",
}));

function About() {
  const { numberSales } = useSpring({
    reset: false,
    from: { numberSales: 0 },
    numberSales: 45,
    delay: 200,
    config: config.molasses,
  });
  const { numberHomes } = useSpring({
    reset: false,
    from: { numberHomes: 0 },
    numberHomes: 39,
    delay: 500,
    config: config.molasses,
  });
  const { numberSatisfaction } = useSpring({
    reset: false,
    from: { numberSatisfaction: 0 },
    numberSatisfaction: 100,
    delay: 1000,
    config: config.molasses,
  });
  const handleScroll = () => {
    let pos = window.scrollY;
    let moved = document.querySelector(".bob");
    moved.style.left = `${-pos / 75 + 10}%`;
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <>
      <Helmet>
        <title>About Jordan Martindale</title>
        <meta
          name="description"
          content="Jordan Martindale is a Hamilton and Haldimand area Real Estate agent who was born and raised in Caledonia, where her family has resided for
              over 4 generations. She prides herself on local market knowledge
              and a desire to serve others in her community. Jordan now enjoys serving both her home town, Caledonia and new
              stompin' grounds in Hamilton as well as neighbouring areas."
        />
      </Helmet>

      <Box sx={{ minHeight: "200vh", backgroundColor: "#F6F4F2" }}>
        <ImageWelcome>
          <Typography color="text.white" style={{ zIndex: "3" }} variant="h2">
            About
          </Typography>
        </ImageWelcome>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ height: "300px" }}
        >
          <Grid
            item
            direction="column"
            container
            sx={{ width: "auto" }}
            alignItems="center"
          >
            <AnimatedTypography variant="h2" sx={{ display: "flex" }}>
              $
              <animated.div>{numberSales.to((n) => n.toFixed(0))}</animated.div>
              M
            </AnimatedTypography>
            <Typography variant="body" sx={{ display: "flex" }}>
              Sales Last Year
            </Typography>
          </Grid>
          <Grid
            item
            direction="column"
            container
            sx={{ width: "auto" }}
            alignItems="center"
          >
            <AnimatedTypography variant="h2">
              <animated.div>{numberHomes.to((n) => n.toFixed(0))}</animated.div>
            </AnimatedTypography>
            <Typography variant="body" sx={{ display: "flex" }}>
              Properties Sold Last Year
            </Typography>
          </Grid>
          <Grid
            item
            direction="column"
            container
            sx={{ width: "auto" }}
            alignItems="center"
          >
            <AnimatedTypography variant="h2">
              <animated.div>
                {numberSatisfaction.to((n) => n.toFixed(0))}
              </animated.div>
              %
            </AnimatedTypography>
            <Typography variant="body" sx={{ display: "flex" }}>
              Happy Clients
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ marginBottom: "200px" }}>
          <Grid
            item
            container
            justifyContent="center"
            direction="column"
            sx={{ maxWidth: "600px", margin: "2%" }}
          >
            <Typography
              variant="h4"
              sx={{ margin: "0 auto", marginBottom: "40px" }}
            >
              About Jordan
            </Typography>
            <Typography variant="body">
              Born and raised in Caledonia, where her family has resided for
              over 4 generations, Jordan has loved and embraced all that the
              town has to offer. She prides herself on local market knowledge
              and a desire to serve others in her community.
              <br />
              <br />
              Since embarking upon her real estate journey in 2018, Jordan's
              business and network quickly expanded to the city of Hamilton,
              where she continued to build upon her experience, gaining a wealth
              of knowledge and insight along the way. She fell so in love with
              the charm of the city and its people that she decided to move
              there in 2021. With her enthusiasm for exploring and socializing,
              Jordan quickly grew acquainted with the city.
              <br />
              <br />
              Jordan now enjoys serving both her home town, Caledonia and new
              stompin' grounds in Hamilton as well as neighbouring areas. Jordan
              blends traditional realtor techniques with new technology and
              endless creativity. She is extremely dedicated to providing value
              and service that leave you feeling confident in your decisions.
              This unique service is complimented by her honest and professional
              opinions and tips along the way, ensuring you have a real estate
              partner who's working for you and your interests with every
              available option.
              <br />
              <br />
              Jordan is practical, analytical and born to serve.
              Relationship-based communication, tailored master marketing plans
              and sharp negotiations are what she stands by to get you the
              results you deserve!
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{ maxWidth: "500px", margin: "2%" }}
          >
            <ImgSlide id="pic1" src={jpic33} />
          </Grid>
        </Grid>

        <ImgBox>
          <ImgContainer className="bob" src={htown2} alt="htown" />
          <Grid
            justifyContent="center"
            alignItems="center"
            sx={{ position: "relative" }}
          >
            <Box
              sx={{
                position: "absolute",
                backdropFilter: "blur(5px)",
                maxWidth: "600px",
                width: "100vw",
                zIndex: "1000",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                padding: "20px",
              }}
            >
              <Typography color="text.white" variant="h3">
                Social Media
              </Typography>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/KellerWilliamsEdgeRealty"
              >
                <FacebookIcon
                  sx={{
                    fontSize: "50px",
                    margin: "10px",
                    "&:hover": {
                      transform: "scale(1.2)",
                      transition: "0.2s ease-in-out",
                      cursor: "pointer",
                    },
                  }}
                  color="white"
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/hamiltonsrealtor_jordan/"
              >
                <InstagramIcon
                  sx={{
                    fontSize: "50px",
                    margin: "10px",
                    "&:hover": {
                      transform: "scale(1.2)",
                      transition: "0.2s ease-in-out",
                      cursor: "pointer",
                    },
                  }}
                  color="white"
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ca.linkedin.com/in/jordan-martindale-502a50108"
              >
                <LinkedInIcon
                  sx={{
                    fontSize: "50px",
                    margin: "10px",
                    "&:hover": {
                      transform: "scale(1.2)",
                      transition: "0.2s ease-in-out",
                      cursor: "pointer",
                    },
                  }}
                  color="white"
                />
              </a>
            </Box>
          </Grid>
        </ImgBox>
      </Box>
    </>
  );
}

export default About;
