import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/system";
import React from "react";
import { useLocation } from "react-router-dom";
import jmlogo78 from "../img/jmlogo78.png";

const Base = styled("div")({
  backgroundColor: "rgb(34,34,34)",
  height: "20vh",
  width: "100vw",
  zIndex: "100000",
});

const LogoWrapper = styled("a")({
  display: "flex",
  position: "relative",
  margin: "5px",
  backgroundColor: " rgb(200,200,200,0.1)",
  border: "2px solid rgb(255,255,255,0.8)",
  borderRadius: "50px",
  padding: "7px",
  "&:after": {
    content: '" "',
    zIndex: 200,
    position: "absolute",
    height: "120%",
    width: "120%",
    borderRadius: "100px",
    top: "-4px",
    left: "-4px",
    right: "0",
    backgroundColor: " rgb(34,34,34,0.2)",
  },
  "&:hover": {
    "&:after": {
      backgroundColor: "rgb(255,255,255,0.0)",
    },
  },
});
function FooterPara() {
  let location = useLocation();
  location = location.pathname.toLowerCase();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      {matches && location === "/home" ? (
        <Base>
          <Grid
            container
            alignItems="center"
            sx={{ height: "100%", width: "100%" }}
          >
            <Grid
              item
              container
              xs={4}
              justifyContent="flex-start"
              sx={{ paddingLeft: "10%" }}
            >
              <LogoWrapper
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/KellerWilliamsEdgeRealty"
              >
                <FacebookIcon
                  color="white"
                  sx={{
                    fontSize: "35px",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "25px",
                    },
                  }}
                />
              </LogoWrapper>
              <LogoWrapper
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/hamiltonsrealtor_jordan/"
              >
                <InstagramIcon
                  color="white"
                  sx={{
                    fontSize: "35px",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "25px",
                    },
                  }}
                />
              </LogoWrapper>
              <LogoWrapper
                target="_blank"
                rel="noopener noreferrer"
                href="https://ca.linkedin.com/in/jordan-martindale-502a50108"
              >
                <LinkedInIcon
                  color="white"
                  sx={{
                    fontSize: "35px",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "25px",
                    },
                  }}
                />
              </LogoWrapper>
            </Grid>
            <Grid item container xs={4} justifyContent="center">
              <img
                src={jmlogo78}
                alt="JM"
                style={{
                  width: "70%",
                  maxWidth: "225px",
                }}
                width="325px"
              />
            </Grid>
            <Grid
              item
              container
              xs={4}
              justifyContent="flex-end"
              sx={{ paddingRight: "10%" }}
            >
              <Typography
                variant="body"
                color="text.white"
                sx={{ fontSize: "12px" }}
              >
                Site By: Lukas Puckerin Web development
              </Typography>
            </Grid>
          </Grid>
        </Base>
      ) : null}
    </>
  );
}

export default FooterPara;
