import BathtubIcon from "@mui/icons-material/Bathtub";
import BedIcon from "@mui/icons-material/Bed";
import CloseIcon from "@mui/icons-material/Close";
import GarageIcon from "@mui/icons-material/Garage";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import { Backdrop, Box, Fade, Grid, Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import comingsoon from "../../img/comingsoon.png";
import { baseurl } from "../../constants";
import Contact from "./Contact";
import Gallery from "./Gallery";
import Maps from "./Maps";

function ListingDetails() {
  const { id } = useParams();
  const [ListingData, setListingData] = useState(null);
  const [open, setOpen] = useState(false);
  const address =
    ListingData && ListingData.UnparsedAddress
      ? ListingData.UnparsedAddress.split(",")[0]
      : "Address not available";

  const mediaURL = ListingData?.Media?.[0]?.MediaURL || comingsoon;

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const url = `${baseurl}/listings/listing/${id}`;
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(url).then((response) => {
      setListingData(response.data.bundle[0]);
      console.log(response.data.total);
      if (response.data.total === 0) {
        console.log("yuppppp");
        navigate("/no-listings");
      }
    });
  }, [url, navigate]);

  const GalleryButton = styled("div")(({ theme }) => ({
    border: "1px solid white",
    zIndex: "20",
    color: "white",
    padding: "8px 40px",
    marginTop: "20px",
    backgroundImage: "linear-gradient(#ffffff, #ffffff)",
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "0% 100%",
    transition: "background-size 0.5s, color 0.5s",

    "&:hover": {
      color: theme.palette.purpleT.main,
      backgroundSize: "100% 100%",
      cursor: "pointer",
    },
  }));

  const Section = styled("div")({
    minHeight: "100vh",
    width: "100%",
  });
  const TopSection = styled("div")({
    backgroundImage: `url(${mediaURL})`,
    height: "100vh",
    maxHeight: "700px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    flexDirection: "column",
    width: "100%",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "70px",
    "&:after": {
      content: '" "',
      zIndex: 2,
      display: "block",
      position: "absolute",
      height: "100vh",
      maxHeight: "700px",
      top: "100",
      left: "0",
      right: "0",
      background: "rgba(0, 0, 0, 0.5)",
    },
  });

  const Gallery2 = styled("div")(({ theme }) => ({
    border: "1px solid white",
    zIndex: "20",
    color: "white",
    padding: "8px 40px",
    marginTop: "20px",
    backgroundImage: `linear-gradient(${theme.palette.purpleT.dark}, ${theme.palette.purpleT.dark}`,
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    transition: "scale 0.5s",

    "&:hover": {
      scale: "110%",

      cursor: "pointer",
    },
  }));

  return (
    <>
      <Helmet>
        <title>{`${ListingData?.UnparsedAddress}`}</title>
        <meta name="description" content={`${ListingData?.PublicRemarks}`} />
      </Helmet>
      <Section>
        <TopSection>
          <Typography color="text.white" style={{ zIndex: "3" }} variant="h2">
            {ListingData?.UnparsedAddress}
          </Typography>
          <GalleryButton onClick={handleToggle}>
            <Typography
              variant="h6"
              sx={{ fontFamily: '"Montserrat", sans-serif' }}
            >
              Gallery
            </Typography>
          </GalleryButton>
        </TopSection>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 4 }}
          sx={{ minHeight: "1000px", padding: "5%" }}
        >
          <Grid
            item
            container
            alignItems="flex-end"
            direction="column"
            xs={12}
            md={7}
          >
            <div style={{ maxWidth: "700px", width: "100%" }}>
              <Typography variant="h5" color="text.medium">
                {ListingData?.City}, {ListingData?.StateOrProvince},{" "}
                {ListingData?.PostalCode?.toUpperCase()}
              </Typography>

              <Typography variant="h3">{address}</Typography>
              <Typography
                variant="h5"
                sx={{ marginBottom: "20px" }}
                color="text.medium"
              >
                Listing Agent: {ListingData?.ListAgentFullName}
              </Typography>
              <Typography
                variant="h3"
                color="text.primary"
                sx={{ margin: "10px 0px", fontFamily: "'Arapey', serif;" }}
              >
                ${ListingData?.ListPrice?.toLocaleString("en")}
              </Typography>

              <Grid item container>
                <Paper
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    padding: "10px",
                    margin: "10px",
                  }}
                >
                  <BedIcon sx={{ marginRight: "10px" }} color="primary" />{" "}
                  <Typography
                    color="text.primary"
                    sx={{ fontSize: "18px", fontWeight: "500" }}
                  >
                    {ListingData?.BedroomsTotal}
                  </Typography>
                </Paper>
                <Paper
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    padding: "10px",
                    margin: "10px",
                  }}
                >
                  <SquareFootIcon
                    sx={{ marginRight: "10px" }}
                    color="primary"
                  />{" "}
                  <Typography
                    color="text.primary"
                    sx={{ fontSize: "18px", fontWeight: "500" }}
                  >
                    {ListingData?.BuildingAreaTotal.toLocaleString("en")} sqft
                  </Typography>
                </Paper>
                <Paper
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    padding: "10px",
                    margin: "10px",
                  }}
                >
                  <BathtubIcon sx={{ marginRight: "10px" }} color="primary" />{" "}
                  <Typography
                    color="text.primary"
                    sx={{ fontSize: "18px", fontWeight: "500" }}
                  >
                    {ListingData?.RAHB_BathsTotal}
                  </Typography>
                </Paper>
                <Paper
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    padding: "10px",
                    margin: "10px",
                  }}
                >
                  <GarageIcon sx={{ marginRight: "10px" }} color="primary" />{" "}
                  <Typography
                    color="text.primary"
                    sx={{ fontSize: "18px", fontWeight: "500" }}
                  >
                    {ListingData?.GarageSpaces}
                  </Typography>
                </Paper>
              </Grid>

              <Grid
                item
                container
                sx={{ marginTop: "20px", borderBottom: "1px solid grey" }}
              ></Grid>
              <Grid item container sx={{ marginTop: "20px" }}>
                <Typography
                  variant="banana"
                  sx={{ fontSize: { xs: "16px", sm: "18px", md: "20px" } }}
                  dangerouslySetInnerHTML={{
                    __html: ListingData?.PublicRemarks,
                  }}
                ></Typography>
              </Grid>
              <Grid
                sx={{ margin: "20px 0px" }}
                item
                container
                justifyContent="center"
              >
                <Gallery2 onClick={handleToggle}>
                  <Typography
                    variant="h6"
                    sx={{ fontFamily: '"Montserrat", sans-serif' }}
                  >
                    View Gallery
                  </Typography>
                </Gallery2>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            md={5}
          >
            <Contact ListingData={ListingData} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Box
            sx={{
              borderBottom: "1px solid",
              borderBottomColor: "purpleT.main",
              width: "60%",
            }}
          ></Box>
          {ListingData?.VirtualTourURLBranded ? (
            <iframe
              title="Virtual Tour"
              style={{
                marginTop: "40px",
                maxWidth: "1400px",
                maxHeight: "600px",
                width: "100%",
                height: "75vh",
                border: "0px",
              }}
              src={ListingData?.VirtualTourURLBranded}
              allowFullScreen
              allow="xr-spatial-tracking"
            ></iframe>
          ) : ListingData?.VirtualTourURLUnbranded ? (
            <iframe
              title="Virtual Tour"
              style={{
                marginTop: "40px",
                maxWidth: "1400px",
                maxHeight: "600px",
                width: "100%",
                height: "75vh",
                border: "0px",
              }}
              src={ListingData?.VirtualTourURLUnbranded}
              allowFullScreen
              allow="xr-spatial-tracking"
            ></iframe>
          ) : null}
          <Maps
            address={address}
            city={ListingData?.City}
            province={ListingData?.StateOrProvince}
            postal={ListingData?.PostalCode}
            longitude={ListingData?.Longitude}
            latitude={ListingData?.Latitude}
          />
        </Grid>

        <Fade timeout={1000} in={open}>
          <Backdrop
            sx={{
              backgroundColor: "rgb(0,0,0,0.99) !important",
              color: "#fff",
              zIndex: "9998",
            }}
            open={open}
          >
            <CloseIcon
              sx={{
                fontSize: "50px",
                position: "absolute",
                top: "0px",
                right: "10px",
                zIndex: "9999",
                color: "white !important",
                "&:hover": {
                  cursor: "pointer",
                  transform: "scale(1.2)",
                  transition: "0.2s ease-in-out",
                },
              }}
              onClick={handleClose}
            ></CloseIcon>
            <Gallery ListingData={ListingData} />
          </Backdrop>
        </Fade>
      </Section>
    </>
  );
}

export default ListingDetails;
