import React, { useEffect, useState } from "react";
// Import Swiper React components
import StarIcon from "@mui/icons-material/Star";
import { Swiper, SwiperSlide } from "swiper/react";
import closequote from "../../img/closequote.svg";
import openquote from "../../img/openquote.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import styles from "../../css/testimonial.module.scss";

// import required modules
import { Grid, Paper, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { Pagination } from "swiper";
import { baseurl } from "../../constants";

const BodyText = styled(Typography)({
  textAlign: "left",
  position: "relative",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "6",
  lineClamp: 6,
  WebkitBoxOrient: "vertical",
  padding: "0px 50px",
  maxHeight: "10em",
});

function Testimonials() {
  const [allTestimonials, setAllTestimonials] = useState([]);

  useEffect(() => {
    axios.get(`${baseurl}/testimonials/testimonials`).then((response) => {
      setAllTestimonials(response.data);
    });
  }, []);

  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className={styles.swiper}
      >
        {allTestimonials?.map((item) => (
          <SwiperSlide className={styles.swiperslide} key={item.id}>
            <Paper
              elevation={10}
              sx={{
                width: "100%",
                // backgroundColor: "rgb(33, 35, 69, 0.95)",
                backgroundColor: "#c2a692",
                padding: "30px 0",
              }}
            >
              <Grid container direction="column">
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <img
                    alt="openquote"
                    style={{
                      width: "30px",
                      position: "absolute",
                      left: "10px",
                      top: "-5px",
                      filter: "brightness(10%)",
                    }}
                    src={openquote}
                  />
                  <BodyText
                    color="text.primary"
                    variant="body"
                    sx={{ fontSize: { xs: "16px", md: "18px" } }}
                    dangerouslySetInnerHTML={{
                      __html: item?.testimonial_text,
                    }}
                  ></BodyText>
                  <img
                    alt="openquote"
                    style={{
                      width: "30px",
                      position: "absolute",
                      right: "10px",
                      bottom: "-5px",
                      filter: "brightness(10%)",
                    }}
                    src={closequote}
                  />
                </Box>
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  sx={{ padding: "20px 50px 0px 50px" }}
                >
                  <Typography color="text.primary" variant="h6">
                    {item?.author} {item?.city ? `(${item?.city})` : ""}
                  </Typography>
                  <Box>
                    <StarIcon color="rgb(34, 34, 34)" />
                    <StarIcon color="rgb(34, 34, 34)" />
                    <StarIcon color="rgb(34, 34, 34)" />
                    <StarIcon color="rgb(34, 34, 34)" />
                    <StarIcon color="rgb(34, 34, 34)" />
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default Testimonials;
