import FacebookIcon from "@mui/icons-material/Facebook";
import LinkIcon from "@mui/icons-material/Link";

import {
  Button,
  ClickAwayListener,
  Fade,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import axios from "axios";
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { baseurl } from "../../constants";
import underline from "../../img/unerline1.svg";

import BlogCardSm from "./BlogCardSm";
import PDFBook from "./PDFBook";

const Mainimg = styled("img")({
  width: "100%",
  marginBottom: "20px",
  maxWidth: "900px",
});

function BlogDetails() {
  const [blogData, setBlogData] = useState(null);
  const [allBlogs, setAllBlogs] = useState(null);
  const [allFetched, setAllFetched] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    axios.get(`${baseurl}/blog/blog/${id}`).then((response) => {
      setBlogData(response.data);
    });

    axios.get(`${baseurl}/blog/blogs`).then((response) => {
      setAllBlogs(response.data.filter((item) => item?.id !== parseInt(id)));
      setAllFetched(true);
    });
  }, [id]);

  const open = (socialLink) => {
    window.open(socialLink, "_blank");
  };

  const handleShare = (e) => {
    e.preventDefault();
    const ahref = window.location.href;
    // const encodedAhref = encodeURIComponent(ahref);
    var link;
    switch (e.currentTarget.id) {
      case "facebook":
        link = `https://www.facebook.com/sharer/sharer.php?u=${ahref}`;
        open(link);
        break;
      case "copy":
        navigator.clipboard.writeText(ahref);
        break;
      default:
        break;
    }
  };

  // const popupState = usePopupState({
  //   variant: "popover",
  //   popupId: "share",
  // });

  return (
    <>
      <Helmet>
        <title> {`Blog - ${blogData?.title}`}</title>
        <meta name="description" content={`${blogData?.subtitle}`} />
      </Helmet>
      <Fade in={allFetched} timeout={{ enter: 1000 }}>
        <div
          style={{
            minHeight: "100vh",
            width: "100%",
            backgroundColor: "#F6F4F2",
            paddingBottom: "40px",
          }}
        >
          <Box
            sx={{
              height: "100px",
              width: "100%",
              backgroundColor: "grey.main",
              marginBottom: "100px",
            }}
          ></Box>
          <Grid container>
            <Grid
              item
              container
              direction="column"
              md={8}
              xs={12}
              sx={{
                padding: { xs: "0% 5% 5% 5%", md: "0% 0% 0% 10%" },
              }}
            >
              <Mainimg src={blogData?.photo_main} />
              <Typography
                sx={{ marginTop: "20px" }}
                variant="h3"
                color="text.secondary"
              >
                {blogData?.title}{" "}
              </Typography>
              <img
                src={underline}
                alt="underline"
                style={{ width: "95%", maxWidth: "400px", marginTop: "-5px" }}
              />
              <Typography
                variant="body"
                color="text.light"
                sx={{ marginBottom: "10px" }}
              >
                By: {blogData?.author}{" "}
              </Typography>
              <Typography
                sx={{ marginBottom: "20px" }}
                variant="body"
                color="text.secondary"
                dangerouslySetInnerHTML={{ __html: blogData?.blog_text }}
              ></Typography>
              {blogData?.photos ? (
                <PDFBook pdf={blogData?.pdf} photos={blogData?.photos} />
              ) : null}

              <PopupState variant="popper" popupId="share">
                {(popupState) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      {...bindToggle(popupState)}
                      color="purpleT"
                      variant="contained"
                      sx={{ width: "100px", margin: "30px auto" }}
                    >
                      Share
                    </Button>
                    <Popper {...bindPopper(popupState)} transition>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                            <ClickAwayListener
                              onClickAway={() => popupState.close()}
                            >
                              <List
                                sx={{
                                  width: "100%",
                                  maxWidth: 360,
                                  bgcolor: "background.paper",
                                }}
                              >
                                <ListItemButton
                                  id="facebook"
                                  onClick={(e) => {
                                    handleShare(e);
                                    popupState.close();
                                  }}
                                >
                                  <ListItemIcon>
                                    <FacebookIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="Facebook" />
                                </ListItemButton>
                                <ListItemButton
                                  id="copy"
                                  onClick={(e) => {
                                    handleShare(e);
                                    popupState.close();
                                  }}
                                >
                                  <ListItemIcon>
                                    <LinkIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="Copy Link" />
                                </ListItemButton>
                              </List>
                            </ClickAwayListener>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                )}
              </PopupState>
            </Grid>
            <Grid
              item
              container
              spacing={3}
              alignItems="center"
              direction="column"
              md={4}
              xs={12}
            >
              <Grid item>
                <Typography variant="h4">Other Newsletters</Typography>
              </Grid>
              {allBlogs?.map((item) => (
                <BlogCardSm key={item.id} info={item} />
              ))}
            </Grid>
          </Grid>
        </div>
      </Fade>
    </>
  );
}

export default BlogDetails;
