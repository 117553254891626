import { Box } from "@mui/material";
import React from "react";

function Maps({ address, city, province, postal, longitude, latitude }) {
  return (
    <>
      <iframe
        title="map"
        style={{
          // border: "2px solid rgb(33, 35, 69, 0.95)",
          border: "0px",
          borderRadius: "5px",
          width: "100%",
          height: "450px",
          maxWidth: "1500px",
          boxShadow: "1px 1px 5px 5px rgb(0,0,0,0.2)",
          margin: "40px 0px",
        }}
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDpbxVE0GkE02roj4V97x2s3XieuMLoe0Q
            &q=${address},${city},${province},${postal}`}
      ></iframe>
    </>
  );
}

export default Maps;
