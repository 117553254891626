import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ebook1 from "./img/ebook1.jpg";
import ebook2 from "./img/ebook2.jpg";
import ebook3 from "./img/ebook3.jpg";
import ebook4 from "./img/ebook4.jpg";
import ebook5 from "./img/ebook5.jpg";
import ebook6 from "./img/ebook6.jpg";
import ebook7 from "./img/ebook7.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import "./css/book.css";
import styles from "./css/book.module.scss";

// import required modules
import { EffectCards, Navigation } from "swiper";

function Book() {
  return (
    <div style={{ overflow: "hidden" }}>
      <Swiper
        style={{
          height: "100%",
          maxHeight: "900px",
          width: "80%",
          maxWidth: "700px",
          "--swiper-navigation-color": "rgb(20,20,20)",
        }}
        effect={"cards"}
        grabCursor={true}
        navigation={true}
        modules={[EffectCards, Navigation]}
        className={styles.swiper}
      >
        <SwiperSlide className={styles.swiperslide}>
          <img
            style={{ objectFit: "cover", height: "100%", width: "100%" }}
            alt="ebook1"
            src={ebook1}
          />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperslide}>
          <img alt="ebook1" src={ebook2} />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperslide}>
          <img alt="ebook1" src={ebook3} />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperslide}>
          <img alt="ebook1" src={ebook4} />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperslide}>
          <img alt="ebook1" src={ebook5} />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperslide}>
          <img alt="ebook1" src={ebook6} />
        </SwiperSlide>
        <SwiperSlide className={styles.swiperslide}>
          <img alt="ebook1" src={ebook7} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Book;
