import MenuIcon from "@mui/icons-material/Menu";
import { Grid, Slide, useScrollTrigger } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import jmlogo77 from "../img/jmlogo77.png";
import jmlogo78 from "../img/jmlogo78.png";
import FadeWrapper from "./FadeWrapper";

const CustomNavLink = styled(NavLink)`
  text-decoration: none;
  position: relative;
  font-family: "Montserrat", sans-serif;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;
const ContactButton = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid white",
  color: "white",
  width: "120px",
  height: "40px",
  backgroundImage: "linear-gradient(#ffffff, #ffffff)",
  marginLeft: "50px",
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "0% 100%",
  transition: "background-size 0.5s, color 0.5s",

  "&:hover": {
    color: "rgb(33, 35, 69, 0.95)",
    backgroundSize: "100% 100%",
    cursor: "pointer",
  },
});
const Img = styled("img")({});

const drawerWidth = 240;
const navItems = ["Home", "Listings", "About", "Newsletters"];

function Navbar(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);

  const containerRef = React.useRef(null);

  React.useEffect(() => {
    setShow(true);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    console.log("clicked");
  };
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  let activeStyle = {
    color: "white",
    textDecoration: "underline",
    textDecorationThickness: "2px",
    textUnderlineOffset: "4.5px",
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img
        src={jmlogo77}
        alt="JM logo"
        style={{
          margin: "10px",
          width: "80%",
        }}
      />
      <Divider />
      <List>
        {navItems.map((item) => (
          <Link
            key={item}
            to={item}
            style={{ textDecoration: "none", color: "#222222" }}
          >
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      {/* <Grow
        style={{ transformOrigin: "top center 0" }}
        direction="down"
        in={show}
        timeout={3000}
        mountOnEnter
        unmountOnExit
      > */}
      <AppBar
        sx={{
          zIndex: "100",
          transition: "500ms",
          height: "100px",
          justifyContent: "center",
          backdropFilter: "blur(10px)",
          backgroundColor:
            trigger || props.trig2 > 0
              ? "rgb(0,0,0,0.7)"
              : "rgb(34, 34, 34,0.0)",
          // background: trigger ? "rgb(0,0,0,0.7)" : "rgb(34, 34, 34,0.0)",
        }}
        elevation={trigger ? 4 : 0}
        // color={trigger ? "whitebg" : "whitebg"}
        component="nav"
      >
        <FadeWrapper duration={2000}>
          <Toolbar ref={containerRef} sx={{ overflow: "hidden" }}>
            <Slide
              timeout={1000}
              in={show}
              direction="up"
              container={containerRef.current}
            >
              <Grid
                sx={{
                  padding: { xs: "0px 10px", md: "0px 10px", lg: "0px 20px" },
                }}
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { md: "none" } }}
                >
                  <MenuIcon />
                </IconButton>

                <Grid
                  item
                  sx={{
                    display: { xs: "flex", md: "none", lg: "flex" },
                    margin: "0 auto",
                  }}
                >
                  <Link to="/home">
                    <Img
                      src={jmlogo78}
                      sx={{
                        marginLeft: "-40px",
                        display: { xs: "block", lg: "block" },
                        width: { xs: "200px", xl: "250px" },
                      }}
                    />
                  </Link>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: { xs: "100%", lg: "auto" },
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 auto",
                      display: { xs: "none", md: "flex" },
                    }}
                  >
                    {navItems.map((item) => (
                      <CustomNavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        to={item.toLowerCase()}
                        key={item}
                        sx={{
                          color: "#fff",
                          m: { xs: 1, md: 1.7, xl: 2 },
                          fontSize: { md: "14px", xl: "16px" },
                        }}
                      >
                        {item}
                      </CustomNavLink>
                    ))}
                    {/* <CustomNavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to={"buyer-resources"}
                      sx={{
                        color: "#fff",
                        m: { xs: 1, md: 1.7, xl: 2 },
                        fontSize: { md: "14px", xl: "16px" },
                      }}
                    >
                      Buyer Resources
                    </CustomNavLink>
                    <CustomNavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to={"seller-resources"}
                      sx={{
                        color: "#fff",
                        m: { xs: 1, md: 1.7, xl: 2 },
                        fontSize: { md: "14px", xl: "16px" },
                      }}
                    >
                      Seller Resources
                    </CustomNavLink> */}

                    <CustomNavLink
                      to="/home#testimonials"
                      sx={{
                        textDecoration: "none!important",
                        color: "#fff",
                        m: { xs: 1, md: 1.7, xl: 2 },
                        fontSize: { md: "14px", xl: "16px" },
                      }}
                    >
                      Testimonials
                    </CustomNavLink>
                    {/* <CustomNavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="why-list"
                      sx={{
                        color: "#fff",
                        m: { xs: 1, md: 2, lg: 2.5 },
                        fontSize: { md: "14px", lg: "16px" },
                      }}
                    >
                      Why List With Me
                    </CustomNavLink> */}
                    <Link to="/home#contact" style={{ textDecoration: "none" }}>
                      <ContactButton
                        sx={{
                          fontSize: { md: "14px", lg: "16px" },
                          display: { xs: "none", sm: "flex" },
                          width: { xs: "90px", lg: "120px" },
                          marginLeft: { xs: "15px", md: "25px", xl: "50px" },
                        }}
                      >
                        Contact
                      </ContactButton>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Slide>
          </Toolbar>
        </FadeWrapper>
      </AppBar>
      {/* </Grow> */}

      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
          <List sx={{ margin: "-16px" }}>
            {/* <ListItem disablePadding>
              <ListItemButton
                sx={{
                  justifyContent: "center",
                  margin: "0px",
                }}
                onClick={() => handleDrawerToggle()}
              >
                <Link
                  style={{ textDecoration: "none", color: "rgb(34,34,34)" }}
                  to="/buyer-resources"
                >
                  <ListItemText primary="Buyer Resources" />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  justifyContent: "center",
                  margin: "0px",
                }}
                onClick={() => handleDrawerToggle()}
              >
                <Link
                  style={{ textDecoration: "none", color: "rgb(34,34,34)" }}
                  to="/seller-resources"
                >
                  <ListItemText primary="Seller Resources" />
                </Link>
              </ListItemButton>
            </ListItem> */}
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  justifyContent: "center",
                  margin: "0px",
                }}
                onClick={() => handleDrawerToggle()}
              >
                <Link
                  style={{ textDecoration: "none", color: "rgb(34,34,34)" }}
                  to="/home#testimonials-mobile"
                >
                  <ListItemText primary="Testimonials" />
                </Link>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  justifyContent: "center",
                  margin: "0px",
                }}
                onClick={() => handleDrawerToggle()}
              >
                <Link
                  style={{ textDecoration: "none", color: "rgb(34,34,34)" }}
                  to="/home#contact-mobile"
                >
                  <ListItemText primary="Contact" />
                </Link>
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </Box>
    </Box>
  );
}

export default Navbar;
