import { CssBaseline, responsiveFontSizes } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Book from "./Book";
import { theme } from "./Theme";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import NoPage from "./components/NoPage";
import { baseurl } from "./constants";
import ListingDetails from "./pages/ListingDetails/ListingDetails";
import About from "./pages/about/About";
import Blog from "./pages/blog/Blog";
import BlogDetails from "./pages/blog/BlogDetails";
import Main from "./pages/home/Main";
import Listings from "./pages/listings/Listings";

import Wrapper from "./Wrapper";
import NoListings from "./pages/ListingDetails/NoListings";
import Buyer from "./pages/resources/Buyer";
import Resources from "./pages/resources/Resources";

function App() {
  const [trig2, setTrig2] = useState(false);

  const location = useLocation().pathname;

  useEffect(() => {
    if (!location.toLowerCase().includes("home")) {
      setTrig2(false);
    }
  }, [location]);

  const themed = responsiveFontSizes(theme);

  useEffect(() => {
    axios.get(`${baseurl}/csrf_cookie`).then((response) => {});
  }, []);

  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

  return (
    <HelmetProvider>
      <Helmet>
        <title>JM Real Estate</title>
        <meta
          name="description"
          content="Buying or Selling real estate in the Hamilton/Haldimand area? Jordan Martindale is a trusted real estate agent serving the Hamilton and Haldimand area"
        />
      </Helmet>
      <ThemeProvider theme={themed}>
        <CssBaseline />
        <Navbar trig2={trig2} />
        <Wrapper>
          <Routes>
            <Route index path="/" element={<Navigate to="/home" />} />
            <Route
              path="home"
              element={<Main trig2={trig2} setTrig2={setTrig2} />}
            />
            <Route path="seller-resources" element={<Resources />} />
            <Route path="buyer-resources" element={<Buyer />} />
            <Route path="newsletters" element={<Blog />} />
            <Route path="newsletters/:id" element={<BlogDetails />} />
            <Route path="about" element={<About />} />
            <Route path="book" element={<Book />} />
            <Route path="listings" element={<Listings />} />
            <Route path="listings/:id" element={<ListingDetails />} />
            {/* <Route path="why-list" element={<WhyUs />} /> */}
            <Route path="/*" element={<NoPage />} />
            <Route path="/no-listings" element={<NoListings />} />
          </Routes>
        </Wrapper>
        <Footer />
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
