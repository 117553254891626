import React from "react";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import DownloadIcon from "@mui/icons-material/Download";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import october1 from "../../img/october1.jpg";
import october2 from "../../img/october2.jpg";
import october3 from "../../img/october3.jpg";
import october4 from "../../img/october4.jpg";
import october5 from "../../img/october5.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import "./css/book.css";
import styles from "../../css/book.module.scss";

// import required modules
import { EffectCards, Navigation } from "swiper";
import { Box, Fade } from "@mui/material";

function PDFBook({ photos, pdf }) {
  const images = photos.map((ele) => ele.image);

  return (
    <>
      <div style={{ alignSelf: "flex-end", zIndex: "1000" }}>
        <a
          href={pdf}
          title="Download"
          download
          style={{
            textDecoration: "none",
          }}
        >
          <DownloadIcon
            fontSize="large"
            style={{
              color: "rgb(33, 35, 69, 0.95)",
              marginRight: "10px",
            }}
          />
        </a>
        <a
          href={pdf}
          target="_blank"
          title="View full screen pdf"
          style={{
            textDecoration: "none",
          }}
          rel="noreferrer"
        >
          <OpenInFullIcon
            fontSize="large"
            style={{ color: "rgb(33, 35, 69, 0.95)" }}
          />
        </a>
      </div>
      <Fade in={true} timeout={350} style={{ transitionDelay: "500ms" }}>
        <Box sx={{ overflow: "hidden", width: "100%" }}>
          <Swiper
            style={{
              height: "100%",
              maxHeight: "900px",
              width: "75%",
              maxWidth: "700px",
              "--swiper-navigation-color": "rgb(20,20,20)",
            }}
            effect={"cards"}
            grabCursor={true}
            navigation={true}
            modules={[EffectCards, Navigation]}
            className={styles.swiper}
          >
            {images?.map((image, i) => (
              <SwiperSlide className={styles.swiperslide} key={i}>
                <img
                  style={{ objectFit: "cover", height: "100%", width: "100%" }}
                  alt={`slide ${i}`}
                  src={image}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Fade>
    </>
  );
}

export default PDFBook;
