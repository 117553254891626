import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import FadeWrapper from "../../components/FadeWrapper";

const ReadButton = styled(Typography)(({ theme }) => ({
  border: "1px solid",
  borderColor: `${theme.palette.purpleT.dark}`,
  color: `${theme.palette.purpleT.dark}`,
  padding: "8px 20px",
  backgroundImage: `linear-gradient(${theme.palette.purpleT.dark}, ${theme.palette.purpleT.dark})`,
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "0% 100%",
  transition: "background-size 0.5s, color 0.5s",

  "&:hover": {
    color: "#ffffff",
    backgroundSize: "100% 100%",
    cursor: "pointer",
  },
}));

function BlogCard({ info }) {
  // const loaded = useProgressiveImage(info?.photo_main);

  return (
    <>
      <Grid container item xs={12} md={6} biiig={4} justifyContent="center">
        <Card
          sx={{
            textDecoration: "none",
            maxWidth: "750px",
            width: "95%",
            padding: "20px 0px",
            borderRadius: "0",
          }}
          elevation={4}
        >
          <FadeWrapper duration={1400} delay={100}>
            <CardContent>
              <Typography
                sx={{
                  margin: "10px",
                  textAlign: "center",
                }}
                gutterBottom
                variant="h4"
                component="div"
              >
                {info?.title}
              </Typography>
            </CardContent>
            <CardMedia
              component="img"
              alt="green iguana"
              image={info?.card_photo}
              sx={{ maxHeight: "400px" }}
            />
            <CardContent>
              <Typography
                sx={{ marginBottom: "20px" }}
                gutterBottom
                variant="h5"
                component="div"
              >
                {info?.subtitle}
              </Typography>
              <Typography
                className="text-clamp"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  lineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
                variant="body2"
                color="text.primary"
                dangerouslySetInnerHTML={{ __html: info?.blog_text }}
              ></Typography>
              <Typography
                sx={{ marginTop: "30px" }}
                variant="body2"
                color="text.medium"
              >
                By: {info.author}
              </Typography>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
              <Link
                to={`/newsletters/${info?.id}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <ReadButton color="purpleT.main" size="large">
                  Read More...
                </ReadButton>
              </Link>
            </CardActions>
          </FadeWrapper>
        </Card>
      </Grid>
    </>
  );
}

export default BlogCard;
