import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "../../css/styles.css";
import styles from "../../css/styles.module.scss";

// import required modules
import { Box } from "@mui/system";
import { FreeMode, Navigation, Thumbs } from "swiper";

function Gallery({ ListingData }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const images = ListingData?.Media
    ? ListingData.Media.map((ele) => ele.MediaURL)
    : [];

  const imgCount = images?.length;

  const smallest = Math.min(6, imgCount);

  return (
    <>
      <Box
        sx={{
          zIndex: "999",
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh)",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
          paddingTop: {
            sm: "0px",
            md: "50px",
          },
        }}
      >
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[FreeMode, Navigation, Thumbs]}
          className={styles.swiper1}
        >
          {images?.map((image, i) => (
            <SwiperSlide key={i} className={styles.swiperslide1}>
              <img src={image} alt="house" className={styles.swiperSlideImg1} />
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={false}
          spaceBetween={10}
          slidesPerView={smallest}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className={styles.swiper2}
        >
          {images?.map((image, i) => (
            <SwiperSlide key={i} className={styles.swiperslide2}>
              <img alt="img" src={image} className={styles.swiperSlideImg2} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </>
  );
}

export default Gallery;
