import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NumericFormat } from "react-number-format";
import { baseurl } from "../../constants";
import listingstop1 from "../../img/listingstop1.jpg";
import ListingCard from "./ListingCard";
//
const ImageWelcome = styled("div")(({ theme }) => ({
  backgroundImage: `url(${listingstop1})`,
  height: "600px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "70px",
  [theme.breakpoints.down("lg")]: {
    height: "500px",
  },
  [theme.breakpoints.down("md")]: {
    height: "400px",
    paddingTop: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "350px",
    paddingTop: "30px",
  },

  "&:after": {
    content: '" "',
    zIndex: 2,
    display: "block",
    position: "absolute",
    height: "600px",
    top: "0",
    left: "0",
    right: "0",
    background: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.down("lg")]: {
      height: "500px",
    },
    [theme.breakpoints.down("md")]: {
      height: "400px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "350px",
    },
  },
}));

const ViewButtonBlue = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  border: `1px solid ${theme.palette.purpleT.dark}`,
  color: "white",
  padding: "8px 20px",
  marginTop: "30px",
  width: "60%",
  borderRadius: "0px",
  backgroundColor: theme.palette.purpleT.dark,
  backgroundImage: "linear-gradient(#ffffff, #ffffff)",
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "0% 100%",
  transition: "background-size 0.5s, color 0.5s",
  zIndex: "1",

  "&:hover": {
    color: theme.palette.purpleT.dark,
    backgroundSize: "100% 100%",
    cursor: "pointer",
    transform: "scale(1.1)",
    transition: "all 0.5s ease-in-out",
  },
}));

function Listings() {
  const [allListings, setAllListings] = useState([]);
  const [total, setTotal] = useState(0);
  const [bedrooms, setBedrooms] = React.useState("");
  const [city, setCity] = React.useState("");
  const [priceMin, setPriceMin] = React.useState("");
  const [priceMax, setPriceMax] = React.useState("");
  const [rentSale, setRentSale] = React.useState("");
  const [offset, setOffset] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageClicked, setPageClicked] = React.useState(false);
  const [searchClicked, setSearchClicked] = React.useState(false); // New state

  // Create local state to temporarily hold filter values
  const [tempBedrooms, setTempBedrooms] = useState("");
  const [tempCity, setTempCity] = useState("");
  const [tempPriceMin, setTempPriceMin] = useState("");
  const [tempPriceMax, setTempPriceMax] = useState("");
  const [tempRentSale, setTempRentSale] = useState("");

  const searchButtonRef = useRef();
  // Fetch initial listings on component mount
  useEffect(() => {
    axios.get(`${baseurl}/listings/listings`).then((response) => {
      setAllListings(response.data.bundle);
      setTotal(response.data.total);
      setLoading(false);
      setCurrentPage(1);
    });
  }, []); // Dependency array is empty to run only on mount

  const fetchData = useCallback(async () => {
    const buildQueryString = () => {
      const params = [];

      if (bedrooms) params.push(`bedrooms=${bedrooms}`);
      if (city) params.push(`city=${city}`);
      if (priceMin) params.push(`price_min=${parseInt(priceMin)}`);
      if (priceMax) params.push(`price_max=${parseInt(priceMax)}`);
      if (rentSale) params.push(`RAHB_TransactionType=${rentSale}`);
      if (offset) params.push(`offset=${offset}`);

      return params.join("&");
    };
    try {
      setLoading(true);
      const endpoint = `${baseurl}/listings/listingsfiltered`;
      const queryString = buildQueryString();
      const response = await axios.get(`${endpoint}?${queryString}`);
      const data = response.data.bundle;

      setAllListings(data);
      setLoading(false);

      setTotal(response.data.total); // You may need to adjust this line based on your API response structure
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }, [bedrooms, city, priceMin, priceMax, rentSale, offset]);

  // Fetch data when search is clicked
  useEffect(() => {
    if (searchClicked) {
      fetchData();
      setSearchClicked(false); // Reset searchClicked after fetching data
    }
  }, [searchClicked, fetchData]); // Added searchClicked dependency

  // Fetch data when page changes
  useEffect(() => {
    if (pageClicked) {
      fetchData();
      setPageClicked(false);
    }
  }, [pageClicked, fetchData]); // Added currentPage dependency

  useEffect(() => {
    // Scroll to the "Search Listings" button after updating listings
    if (pageClicked) {
      const buttonRect = searchButtonRef.current.getBoundingClientRect();
      window.scrollTo({
        top: window.scrollY + buttonRect.top - 250,
        behavior: "smooth",
      });
    }
  }, [pageClicked]); // Corrected dependency to loading

  const handlePageChange = (event, value) => {
    setOffset(value * 10 - 10);
    setCurrentPage(value);
    setPageClicked(true);
  };

  const handleSearchClick = () => {
    setBedrooms(tempBedrooms);
    setCity(tempCity);
    setPriceMin(tempPriceMin);
    setPriceMax(tempPriceMax);
    setRentSale(tempRentSale);

    setSearchClicked(true);
    setCurrentPage(1);
    setOffset(0);
    fetchData();
  };

  return (
    <>
      <Helmet>
        <title>Featured Real Estate Listings</title>
        <meta
          name="description"
          content="Buying or Selling real estate in the Hamilton/Haldimand area? Check out current real estate listings from Jordan Martindale"
        />
      </Helmet>
      <div
        style={{
          minHeight: "120vh",
          backgroundColor: "#F6F4F2",
        }}
      >
        <ImageWelcome>
          <Typography color="text.white" style={{ zIndex: "3" }} variant="h2">
            Featured Listings
          </Typography>
        </ImageWelcome>
        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="bed1">Bedrooms</InputLabel>
            <Select
              labelId="bed1"
              value={tempBedrooms}
              onChange={(e) => {
                setTempBedrooms(e.target.value);
              }}
              label="Bedrooms"
              sx={{
                color: "rgb(33, 35, 69, 0.95)",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(33, 35, 69, 0.95)",
                },
                "& .MuiOutlinedInput-notchedOutline:hover": {
                  //not working
                  borderColor: "rgb(33, 35, 69, 0.95)",
                },
                "& .MuiOutlinedInput-notchedOutline:focus": {
                  //not working
                  borderColor: "rgb(33, 35, 69, 0.95)",
                },
              }}
            >
              <MenuItem value="">
                <em>Any</em>
              </MenuItem>
              <MenuItem value={1}>1+</MenuItem>
              <MenuItem value={2}>2+</MenuItem>
              <MenuItem value={3}>3+</MenuItem>
              <MenuItem value={4}>4+</MenuItem>
              <MenuItem value={5}>5+</MenuItem>
              <MenuItem value={6}>6+</MenuItem>
              <MenuItem value={7}>7+</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="rentalsale1">Sale or Rental</InputLabel>
            <Select
              labelId="rentalsale1"
              value={tempRentSale}
              onChange={(e) => {
                setTempRentSale(e.target.value);
              }}
              label="Sale or Rental"
              sx={{
                color: "rgb(33, 35, 69, 0.95)",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(33, 35, 69, 0.95)",
                },
                "& .MuiOutlinedInput-notchedOutline:hover": {
                  //not working
                  borderColor: "rgb(33, 35, 69, 0.95)",
                },
                "& .MuiOutlinedInput-notchedOutline:focus": {
                  //not working
                  borderColor: "rgb(33, 35, 69, 0.95)",
                },
              }}
            >
              <MenuItem value="">
                <em>Any</em>
              </MenuItem>
              <MenuItem value={"Sale"}>Sale</MenuItem>
              <MenuItem value={"Lease/Rent"}>Rental</MenuItem>
            </Select>
          </FormControl>
          <FormControl focused variant="outlined" sx={{ m: 1, minWidth: 120 }}>
            <NumericFormat
              customInput={TextField}
              variant="outlined"
              label="Min Price"
              thousandSeparator={true}
              // onChange={(e) => {
              //   setPriceMin(e.target.value);
              // }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onValueChange={(values) => {
                const { floatValue } = values;
                setTempPriceMin(floatValue);
              }}
              sx={{
                color: "rgb(33, 35, 69, 0.95)",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(33, 35, 69, 0.95)",
                },
                "& .MuiOutlinedInput-notchedOutline:hover": {
                  //not working
                  borderColor: "rgb(33, 35, 69, 0.95)",
                },
                "& .MuiOutlinedInput-notchedOutline:focus": {
                  //not working
                  borderColor: "rgb(33, 35, 69, 0.95)",
                },
              }}
              autoComplete="off"
            />
          </FormControl>
          <FormControl
            focused
            className="col "
            variant="outlined"
            sx={{ m: 1, minWidth: 120 }}
          >
            <NumericFormat
              customInput={TextField}
              variant="outlined"
              label="Max Price"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { floatValue } = values;
                setTempPriceMax(floatValue);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              autoComplete="off"
              sx={{
                color: "rgb(33, 35, 69, 0.95)",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(33, 35, 69, 0.95)",
                },
                "& .MuiOutlinedInput-notchedOutline:hover": {
                  //not working
                  borderColor: "rgb(33, 35, 69, 0.95)",
                },
                "& .MuiOutlinedInput-notchedOutline:focus": {
                  //not working
                  borderColor: "rgb(33, 35, 69, 0.95)",
                },
              }}
            />
          </FormControl>

          <TextField
            onChange={(e) => {
              setTempCity(e.target.value);
            }}
            label="City"
            value={tempCity}
            variant="outlined"
            sx={{
              m: 1,
              minWidth: 120,
              color: "purpleT.dark",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "purpleT.dark",
              },
              "& .MuiOutlinedInput-notchedOutline:hover": {
                //not working
                borderColor: "purpleT.dark",
              },
              "& .MuiOutlinedInput-notchedOutline:focus": {
                //not working
                borderColor: "purpleT.dark",
              },
            }}
          />
        </Grid>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <ViewButtonBlue
            onClick={handleSearchClick}
            sx={{ marginTop: "20px" }}
            ref={searchButtonRef}
          >
            Search Listings
          </ViewButtonBlue>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "150px",
            }}
          >
            <CircularProgress color="purpleT" sx={{ margin: "0 auto" }} />
          </Box>
        ) : (
          <Grid
            container
            sx={{ padding: "100px 2%" }}
            spacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
            justifyContent="center"
          >
            {allListings?.map((item) => (
              <ListingCard key={item.ListingId} info={item} />
            ))}
            {total >= 1 ? null : (
              <Typography
                variant="h5"
                style={{ color: "black", width: "100%", textAlign: "center" }}
              >
                No Listings Found
              </Typography>
            )}

            {/* <Button style={{ marginTop: "30px" }} variant="contained">
            <a
              style={{ color: "white", textDecoration: "none" }}
              href="https://www.realtor.ca/agent/2048085/jordan-martindale-640-riverbend-drive-unit-b-kitchener-ontario-n2k3s2#firstname=jordan&lastname=martindale&page=1&sort=3-A"
              target="_blank"
              rel="noreferrer"
            >
              View on Realtor.ca
            </a>
          </Button> */}
            <Stack
              spacing={3}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "40px",
                width: "100%",
              }}
            >
              <Pagination
                count={Math.ceil(total / 10) || 10}
                page={currentPage ?? 1}
                color="purpleT"
                onChange={handlePageChange}
              />
            </Stack>
          </Grid>
        )}
      </div>
    </>
  );
}

export default Listings;
