import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { baseurl } from "../../constants";

const SendButton = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  border: "1px solid white",
  color: "white",
  margin: "40px",
  padding: "8px 20px",
  width: "200px",
  borderRadius: "0px",
  backgroundImage: "linear-gradient(#ffffff, #ffffff)",
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "0% 100%",
  transition: "background-size 0.5s, color 0.5s",

  "&:hover": {
    color: `${theme.palette.purpleT.dark}`,
    backgroundSize: "100% 100%",
    cursor: "pointer",
  },
}));

const CssTextField = styled(TextField)(({ theme }) => ({
  "& input:-webkit-autofill": {
    WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.purpleT.dark} inset !important`,
  },
  "& label.Mui-focused": {
    color: "#ffffff",
  },
  "& .MuiOutlinedInput-input": {
    color: "#ffffff",
  },
  "& .MuiInputLabel-root": {
    color: "#ffffff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ffffff",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "&:hover fieldset": {
      borderColor: "#ffffff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ffffff",
    },
  },
}));

function ContactMain() {
  const [sent, setSent] = useState(null);
  const [sending, setSending] = useState(false);
  const theme = useTheme();

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    first_name: yup.string().max(50, "Too Long!").required("Required"),
    last_name: yup.string().max(50, "Too Long!").required("Required"),
    message: yup
      .string("Enter a message")
      .min(1, "Too Short!")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSending(true);
      axios
        .post(`${baseurl}/emailer/send`, values)
        .then(function (response) {
          setSent("Email Sent!");
          setSending(false);
        })
        .catch(function (error) {
          setSent("Failed to Send");
          setSending(false);
        });
    },
  });

  return (
    <>
      <Paper
        elevation={7}
        sx={{
          zIndex: "99",
          maxWidth: "700px",
          width: "90%",
          padding: { xs: "30px 10px", sm: '"40px 10px",' },
          backgroundColor: "purpleT.dark",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid item container direction="column" alignItems="center">
            <Typography
              color="text.white"
              variant="h4"
              sx={{ fontWeight: "500", fontFamily: '"Montserrat", sans-serif' }}
            >
              Contact
            </Typography>
            <Box
              sx={{
                margin: "20px 0px",
                width: "70%",
                borderBottom: "1px solid #ffffff",
              }}
            ></Box>
            <Grid container item justifyContent="center">
              <CssTextField
                sx={{
                  width: { xs: "70%", sm: "40%" },
                  margin: { xs: "5px", sm: "10px" },
                  input: {
                    color: "#ffffff",
                    backgroundColor: "transparent",
                  },
                }}
                id="first_name"
                name="first_name"
                label="First Name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
              <CssTextField
                sx={{
                  width: { xs: "70%", sm: "40%" },
                  margin: { xs: "5px", sm: "10px" },
                  input: {
                    color: "#ffffff",
                  },
                }}
                id="last_name"
                name="last_name"
                label="Last Name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />

              <CssTextField
                sx={{
                  width: { xs: "70%", sm: "40%" },
                  margin: { xs: "5px", sm: "10px" },
                  input: {
                    color: "#ffffff",
                  },
                }}
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />

              <CssTextField
                sx={{
                  width: { xs: "70%", sm: "40%" },
                  margin: { xs: "5px", sm: "10px" },
                  input: {
                    color: "#ffffff",
                  },
                }}
                id="phone"
                name="phone"
                label="Phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              <CssTextField
                id="message"
                label="Message"
                multiline
                rows={6}
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
                sx={{
                  margin: { xs: "20px", sm: "25px" },
                  width: "90%",
                }}
              />
            </Grid>
            <SendButton
              type="submit"
              sx={{
                margin: { xs: "5px", sm: "10px" },
                display: !sent && !sending ? "flex" : "none",
              }}
            >
              Send Message
            </SendButton>
            <Button
              sx={{
                justifyContent: "center",
                border: "1px solid white",
                color: "white",
                padding: "8px 20px",
                width: "200px",
                borderRadius: "0px",
                backgroundImage: "linear-gradient(#ffffff, #ffffff)",
                backgroundPosition: "50% 50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "0% 100%",
                transition: "background-size 0.5s, color 0.5s",
                margin: { xs: "5px", sm: "10px" },
                display: sending ? "flex" : "none",
              }}
            >
              <CircularProgress color="white" size={27} />
            </Button>
            <SendButton
              sx={{
                margin: { xs: "5px", sm: "10px" },
                display: sent && !sending ? "flex" : "none",
              }}
            >
              {sent}
            </SendButton>
          </Grid>
        </form>
      </Paper>
    </>
  );
}

export default ContactMain;
