import { Box } from "@mui/material";
import React from "react";

function Buyer() {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#222222",
        color: "#ffffff",
        fontSize: { xs: "1em", md: "1.3em", lg: "1.5em" },
        padding: "15px",
      }}
    >
      Buyer Resources Page Coming Sooon!
    </Box>
  );
}

export default Buyer;
