import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { baseurl } from "../../constants";

const SendButton = styled(Button)(({ theme }) => ({
  width: "200px",
  border: `1px solid ${theme.palette.purpleT.dark}`,
  borderRadius: "0px",
  zIndex: "20",
  color: `${theme.palette.purpleT.dark}`,
  padding: "8px 40px",
  marginTop: "20px",
  backgroundImage: `linear-gradient(${theme.palette.purpleT.dark}, ${theme.palette.purpleT.dark})`,
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "0% 100%",
  transition: "background-size 0.5s, color 0.5s",

  "&:hover": {
    color: "white",
    backgroundSize: "100% 100%",
    cursor: "pointer",
  },
}));

function Contact({ ListingData }) {
  const [sent, setSent] = useState(null);
  const [sending, setSending] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    first_name: yup.string().max(50, "Too Long!").required("Required"),
    last_name: yup.string().max(50, "Too Long!").required("Required"),
    message: yup
      .string("Enter a message")
      .min(1, "Too Short!")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
      house: `${ListingData?.UnparsedAddress}`,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSending(true);
      axios
        .post(`${baseurl}/emailer/send`, values)
        .then(function (response) {
          setSent("Email Sent!");
          setSending(false);
        })
        .catch(function (error) {
          setSent("Failed to Send");
          setSending(false);
        });
    },
  });
  return (
    <>
      <Paper
        elevation={7}
        sx={{
          border: "2px solid",
          borderColor: "purpleT.dark",
          maxWidth: "450px",
          margin: "30px 0px",
          padding: "40px 10px",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid item container direction="column" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                backgroundColor: "purpleT.dark",
                color: "#ffffff",
                margin: "20px",
                padding: "10px 20px",
                borderRadius: "2px",
                fontWeight: "500",
              }}
            >
              {ListingData?.is_sold ? "Sold!" : "For Sale"}
            </Typography>
            <Typography variant="monh4" sx={{ fontWeight: "500" }}>
              Contact Us
            </Typography>
            <Box
              sx={{
                margin: "20px 0px",
                width: "80%",
                borderBottom: "1px solid grey",
              }}
            ></Box>
            <Grid container item justifyContent="center">
              <TextField
                sx={{ maxWidth: "190px", width: "90%", margin: "10px" }}
                id="first_name"
                name="first_name"
                label="First Name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
              <TextField
                sx={{ maxWidth: "190px", width: "90%", margin: "10px" }}
                id="last_name"
                name="last_name"
                label="Last Name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />

              <TextField
                sx={{ maxWidth: "190px", width: "90%", margin: "10px" }}
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />

              <TextField
                sx={{ maxWidth: "190px", width: "90%", margin: "10px" }}
                id="phone"
                name="phone"
                label="Phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              <TextField
                id="message"
                label="Message"
                multiline
                rows={4}
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
                sx={{ margin: "40px 0px", width: "80%" }}
              />
              <TextField
                id="house"
                label="Message"
                value={formik.values.house}
                onChange={formik.handleChange}
                sx={{ display: "none" }}
              />
            </Grid>
            <SendButton
              type="submit"
              sx={{
                margin: { xs: "5px", sm: "10px" },
                display: !sent && !sending ? "flex" : "none",
              }}
            >
              Send Message
            </SendButton>
            <Button
              sx={{
                width: "200px",
                border: "1px solid",
                borderColor: "purpleT.dark",
                borderRadius: "0px",
                zIndex: "20",
                color: "purpleT.dark",
                padding: "8px 40px",
                marginTop: "20px",
                backgroundImage: (theme) =>
                  `linear-gradient(${theme.palette.purpleT.dark}, ${theme.palette.purpleT.dark})`,
                backgroundPosition: "50% 50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "0% 100%",
                transition: "background-size 0.5s, color 0.5s",
                display: sending ? "flex" : "none",
              }}
            >
              <CircularProgress color="purpleT" size={27} />
            </Button>
            <SendButton
              sx={{
                margin: { xs: "5px", sm: "10px" },
                display: sent && !sending ? "flex" : "none",
              }}
            >
              {sent}
            </SendButton>
          </Grid>
        </form>
      </Paper>
    </>
  );
}

export default Contact;
