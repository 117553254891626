import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import FadeWrapper from "../../components/FadeWrapper";
import { baseurl } from "../../constants";
import blogtop from "../../img/blogtop.jpg";
import BlogCard from "./BlogCard";
import BlogCardBook from "./BlogCardBook";
import BlogCardOct from "./BlogCardOct";

const ImageWelcome = styled("div")(({ theme }) => ({
  backgroundImage: `url(${blogtop})`,
  height: "600px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "70px",
  [theme.breakpoints.down("lg")]: {
    height: "500px",
  },
  [theme.breakpoints.down("md")]: {
    height: "400px",
    paddingTop: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "350px",
    paddingTop: "30px",
  },

  "&:after": {
    content: '" "',
    zIndex: 2,
    display: "block",
    position: "absolute",
    height: "600px",
    top: "0",
    left: "0",
    right: "0",
    background: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.down("lg")]: {
      height: "500px",
    },
    [theme.breakpoints.down("md")]: {
      height: "400px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "350px",
    },
  },
}));

const BlogSection = styled("div")(({ theme }) => ({
  minHeight: "200vh",
  backgroundColor: "#F6F4F2",
}));

function Blog() {
  const [allBlogs, setAllBlogs] = useState([]);

  useEffect(() => {
    axios.get(`${baseurl}/blog/blogs`).then((response) => {
      setAllBlogs(response.data);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Featured Real Estate Newletters</title>
        <meta
          name="description"
          content="Buying or Selling real estate in the Hamilton/Haldimand area? Check out our newsletters to learn the ins and outs of the Hamilton area real estate market."
        />
      </Helmet>
      <BlogSection>
        <ImageWelcome>
          <Typography color="text.white" style={{ zIndex: "3" }} variant="h2">
            <FadeWrapper duration={1000} start={0.1}>
              Newsletters
            </FadeWrapper>
          </Typography>
        </ImageWelcome>
        <Grid
          container
          sx={{ padding: "100px 2%" }}
          spacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          justifyContent="center"
        >
          {allBlogs.map((item) => (
            <BlogCard key={item.id} info={item} />
          ))}
        </Grid>
      </BlogSection>
    </>
  );
}

export default Blog;
