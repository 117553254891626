import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import ebookp from "../../img/ebookp.jpg";
import octobercover from "../../img/octobercover.jpg";
import FadeWrapper from "../../components/FadeWrapper";
const ReadButton = styled(Typography)({
  border: "1px solid rgb(33, 35, 69, 0.95)",
  color: "rgb(33, 35, 69, 0.95)",
  padding: "8px 20px",
  backgroundImage:
    "linear-gradient(rgb(33, 35, 69, 0.95), rgb(33, 35, 69, 0.95))",
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "0% 100%",
  transition: "background-size 0.5s, color 0.5s",

  "&:hover": {
    color: "white",
    backgroundSize: "100% 100%",
    cursor: "pointer",
  },
});
function BlogCardOct() {
  return (
    <>
      <Grid container item xs={12} md={6} biiig={4} justifyContent="center">
        <Card
          sx={{
            textDecoration: "none",
            maxWidth: "800px",
            width: "95%",
            padding: "20px 0px",
            borderRadius: "0",
          }}
          elevation={4}
        >
          <FadeWrapper duration={1400} delay={100}>
            <CardContent>
              <Typography
                sx={{
                  margin: "10px",
                  textAlign: "center",
                }}
                gutterBottom
                variant="h4"
                component="div"
              >
                October Real Estate Newsletter
              </Typography>
            </CardContent>
            <CardMedia
              component="img"
              alt="october"
              image={octobercover}
              sx={{ maxHeight: "400px" }}
            />
            <CardContent>
              <Typography
                sx={{ marginBottom: "20px" }}
                gutterBottom
                variant="h5"
                component="div"
              >
                Newsletter
              </Typography>
              <Typography
                className="text-clamp"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  lineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
                variant="body2"
                color="text.primary"
              >
                Favourite pumpkin recipe, Fall events, and Fall lawn & yard
                maintenance
              </Typography>
              <Typography
                sx={{ marginTop: "30px" }}
                variant="body2"
                color="text.medium"
              >
                By: Jordan Martindale
              </Typography>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
              <Link
                to={`/newsletters/october`}
                style={{
                  textDecoration: "none",
                }}
              >
                <ReadButton color="purpleT" size="large">
                  Read More...
                </ReadButton>
              </Link>
            </CardActions>
          </FadeWrapper>
        </Card>
      </Grid>
    </>
  );
}

export default BlogCardOct;
