import BathtubIcon from "@mui/icons-material/Bathtub";
import BedIcon from "@mui/icons-material/Bed";
import { Box, Card, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import comingsoon from "../../img/comingsoon.png";

function ListBox({ info }) {
  const [hovered, setHovered] = useState(false);
  const mediaURL = info?.Media?.[0]?.MediaURL || comingsoon;

  const props = useSpring({
    to: {
      height: hovered ? "100px" : "0px",
      opacity: hovered ? 1 : 0,
    },
    from: { height: "0px", opacity: 0 },
  });
  const CardBottom = styled("div")(({ theme }) => ({
    display: "flex",
    width: "100%",
    backgroundColor: "transparent",
    minHeight: "100px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 40px",
  }));

  const CardBottomPop = styled("div")(({ theme }) => ({
    color: "white",
    height: "auto",
    width: "100%",
    transition: "height 4s",
    transformOrigin: "bottom",
    display: "flex",
    backgroundColor: "transparent",
    alignItems: "center",
  }));

  const ListCardContainer = styled(Card)(({ theme }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
    height: "500px",
    borderRadius: "0",
    backgroundImage: `url(${mediaURL})`,
    backgroundSize: "cover",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      height: "450px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "350px",
    },

    "&:hover:after": {
      visibility: "visible",
      width: "100%",
      height: "100%",
      zIndex: "10",
      cursor: "pointer",
    },
    " &:hover:before": {
      visibility: "visible",
      zIndex: "13",
      cursor: "pointer",
    },
  }));

  const SoldBanner = styled("div")({
    width: "400px",
    padding: "10px",
    backgroundColor: "#222222",
    left: "-150px",
    top: "5%",
    color: "#fff",
    textAlign: "center",
    position: "absolute",
    transform: "rotate(-45deg)",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.4)",
    textTransform: "uppercase",
    textShadow: "0 2px 2px rgba(0, 0, 0, 0.4)",
  });

  const DetailsTypography = styled(Typography)(({ theme }) => ({
    border: "1px solid white",
    color: "white",
    position: "absolute",
    right: "50px",
    bottom: "30px",
    padding: "8px 20px",
    backgroundImage: "linear-gradient(#ffffff, #ffffff)",
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "0% 100%",
    transition: "background-size 0.5s, color 0.5s",

    "&:hover": {
      color: theme.palette.primary.main,
      backgroundSize: "100% 100%",
      cursor: "pointer",
    },
  }));
  return (
    <Link
      to={`/listings/${info?.ListingId}`}
      style={{ textDecoration: "none", maxWidth: "800px", width: "95%" }}
    >
      <ListCardContainer
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {/* <SoldBanner hidden={!info?.is_sold}>SOLD</SoldBanner> */}
        <Box sx={{ backgroundColor: "purpleT.dark" }}>
          <CardBottom>
            <Typography variant="h4" color="text.white">
              {info?.UnparsedAddress}
            </Typography>
            <Typography
              sx={{ fontFamily: "'Arapey', serif;" }}
              variant="h4"
              color="text.white"
            >
              ${info?.ListPrice.toLocaleString("en")}
            </Typography>
          </CardBottom>
          <CardBottomPop>
            <animated.div style={props}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  left: "40px",
                  bottom: "60px",
                  color: "white",
                }}
                variant="banana"
                color="text.tertiary"
              >
                {info?.City}, {info?.StateOrProvince},{" "}
                {info?.PostalCode?.toUpperCase()}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  left: "40px",
                  bottom: "20px",
                  color: "white",
                }}
                variant="banana"
                color="text.tertiary"
              >
                <BedIcon sx={{ marginRight: "10px" }} /> {info?.BedroomsTotal}{" "}
                BD |
                <BathtubIcon sx={{ margin: "0px 10px" }} />{" "}
                {info?.BathroomsTotalInteger} BA | &nbsp;
                {info?.BuildingAreaTotal.toLocaleString("en")} sqft
              </Typography>
              <DetailsTypography sx={{ display: { xs: "none", lg: "flex" } }}>
                View Property
              </DetailsTypography>
            </animated.div>
          </CardBottomPop>
        </Box>
        {/* <Typography sx={{ margin: 2 }} variant="h4" color="text.tertiary">
            {info.title}
          </Typography>
          <Typography variant="h6" color="text.tertiary">
            {info.city}, {info.province}, {info.zipcode.toUpperCase()}
          </Typography>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            variant="h6"
            color="text.tertiary"
          >
            <BedIcon sx={{ margin: "0px 10px" }} /> {info.bedrooms} BD |
            <BathtubIcon sx={{ margin: "0px 10px" }} /> {info.bathrooms} BA |
            &nbsp;
            {info.sqft.toLocaleString("en")} sqft
          </Typography>
          <Typography variant="h6" color="text.tertiary">
            ${info.price.toLocaleString("en")}
          </Typography> */}
      </ListCardContainer>
    </Link>
  );
}

export default ListBox;
